import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

import styles from './spektrix-donation.module.scss';

const SpektrixDonation = ({ fundId, clientName, customDomain }) => {

  return (
    <div>
      <spektrix-donate client-name={clientName} fund-id={fundId} custom-domain={customDomain} cumulative-mode>
        <div className={styles.container}>
          <ul className={styles.options}>
            {/* TODO: swap out options with values from API call */}
            <li className={styles.option}>
              <div className={styles.optionContent}>
                <img src="https://citysprings.com/images/site-default-image.png" alt="" />
                <div className={styles.optionDesc}>
                  <h3>Support Option One</h3>
                  <p>Option One description... maecenas sed diam eget risus varius blandit sit amet non magna. Donec sed odio dui.</p>
                </div>
              </div>
              <Button data-donate-amount={10}>$10</Button>
            </li>
            <li className={styles.option}>
              <div className={styles.optionContent}>
                <img src="https://citysprings.com/images/site-default-image.png" alt="" />
                <div className={styles.optionDesc}>
                  <h3>Support Option Two</h3>
                  <p>Option Two description... Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                </div>
              </div>
              <Button data-donate-amount={10}>$10</Button>
            </li>
          </ul>
          <p className={styles.customOption}>Other amount: $<input type="number" min="0" data-custom-donation-input /></p>
          <p className={styles.total}>Total Donation: $<span data-display-donation-amount></span></p>
          <p className={styles.success} data-success-container style={{display: `none`}}>Thank you! Your donation was successfully added.</p>
          <p className={styles.fail} data-fail-container style={{display: `none`}}>Oops! Something went wrong. Please try again.</p>
          <div className={styles.submitOptions}>
            <Button color={`blueMedium`} data-submit-donation>Donate</Button>
            <Button color={`blueLight`} data-clear-donation>Reset Donation</Button>
          </div>
        </div>
      </spektrix-donate>
    </div>
  )
}

SpektrixDonation.propTypes = {
  fundId: PropTypes.string,
  clientName: PropTypes.string,
  customDomain: PropTypes.string
}

export default SpektrixDonation;
