import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import DefaultLayout from '../components/default-layout/default-layout';
import ContentDisplay from '../components/content-display/content-display';
import SEO from '../components/seo/seo';
import SpektrixDonation from '../components/spektrix-donation/spektrix-donation';

const DonatePage = ({ data }) => {
  return (
    <DefaultLayout data={data}>
      <SEO
        title="City Springs Donations"
        description="Donation information for City Springs."
        siteConfig={data.site.siteMetadata}
        pageUrl="/donate"
      />
      <ContentDisplay
        title={'Donate to City Springs'}
        fullWidthLayout={true}
        imageLocation={'none'}
        body={
          '<p>Thank you for supporting City Springs</p>'
        }
      >
        <SpektrixDonation
          fundId={'201AGBHDRLQHNHPHKKMPKLGPMDRDTDMVL'}
          clientName={data.site.siteMetadata.spektrixClientName}
          // Remove the https:// from the site url because custom domain only wants
          // the name.
          customDomain={data.site.siteMetadata.spektrixAPI.replace('https://', '')}
        />
      </ContentDisplay>
    </DefaultLayout>
  );
};

DonatePage.propTypes = {
  data: PropTypes.object
};

export default DonatePage;

export const query = graphql`
  query DonatePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
  }
`;
